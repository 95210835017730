<template>
  <div id="AssessmentList" class="assessment-list">
    <app-title-content
      class="mb-4"
      :root="rootBreadcrumb"
      name="AssessmentList"
    ></app-title-content>

    <div class="align-items-center d-flex my-4">
      <div class="align-items-baseline d-flex flex-fill">
        <div class="font-headblue fontsize-h4">
          <iconify icon="majesticons:clipboard-list"></iconify>
          <span class="weight-700 ml-1"
            >รายการประเมินคุณภาพระบบประปาหมู่บ้านล่าสุด
          </span>
        </div>
        <span class="ml-2">จำนวน {{ total | toCommas }} รายการ</span>
      </div>
      <div>
        <button type="button" class="btn btn-bluesystem" @click="viewAll">
          ดูทั้งหมด
        </button>
      </div>
    </div>
    <b-table
      id="my-table"
      class="table-custom box-shadow mb-4"
      hover
      borderless
      show-empty
      :items="items"
      :fields="fields"
      :busy.sync="isBusy"
    >
      <template v-slot:empty="">
        <div class="text-center fontsize-h4 my-4">
          ท่านยังไม่เคยทำแบบประเมินคุณภาพระบบประปา
        </div>
      </template>

      <div slot="table-busy" class="text-center font-blueinfo my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong class="pl-2">กำลังโหลด...</strong>
      </div>

      <template v-slot:head()="data">
        <div
          :class="
            data.field.align === 'left'
              ? 'text-left'
              : data.field.align === 'right'
              ? 'text-right'
              : 'text-center'
          "
        >
          {{ data.label }}
        </div>
      </template>

      <template v-slot:head(index)="data">
        <div class="text-center">
          {{ data.label }}
        </div>
      </template>

      <!-- Row Custom align -->
      <template v-slot:cell()="data">
        <div
          v-if="data.item.alignRow !== undefined"
          :class="
            data.item.alignRow === 'left'
              ? 'text-left'
              : data.item.alignRow === 'right'
              ? 'text-right'
              : 'text-center'
          "
        >
          {{ data.value }}
        </div>
        <div
          v-else
          :class="
            data.field.align === 'left'
              ? 'text-left'
              : data.field.align === 'right'
              ? 'text-right'
              : 'text-center'
          "
        >
          {{ data.value }}
        </div>
      </template>

      <template #cell(result)="row">
        <div>
          <iconify
            :icon="getIcon(row.item.assessmentResult.statusTextSymbol)"
            :class="getColorIcon(row.item.assessmentResult.statusTextSymbol)"
          />
          {{ row.item.assessmentResult.statusText }}
        </div>
      </template>

      <template v-slot:cell(viewResult)="data">
        <div class="d-flex">
          <font-awesome-icon
            icon="file-alt"
            class="fontsize-h2 font-systemblue pr-2"
          />
          <a
            class="font-systemblue cursor-pointer"
            @click="onViewResult(data.item)"
            >เรียกดู</a
          >
        </div>
      </template>

      <template v-slot:cell(canEdit)="data">
        <div class="text-center">
          <button
            type="button"
            class="btn btn-edit"
            :disabled="!data.item.canEdit"
            @click="editRow(data.item)"
          >
            <font-awesome-icon
              :icon="['fas', 'edit']"
              class="color-icon-edit fa-lg"
            />
          </button>
        </div>

        <!-- <div v-if="data.item.canEdit" class="text-center">
            <font-awesome-icon
              :icon="['fas', 'edit']"
              @click="editRow(data.item)"
              class="color-icon-edit cursor-pointer"
            />
          </div>
          <div v-else class="text-center">
            <font-awesome-icon
              :icon="['fas', 'edit']"
              class="color-icon-edit"
              disabled
            />
          </div> -->
      </template>

      <template v-slot:cell(canDelete)="data">
        <div class="text-center">
          <button
            type="button"
            class="btn btn-trash"
            :disabled="!data.item.canDelete"
            @click="deleteRow(data.item)"
          >
            <font-awesome-icon
              :icon="['fas', 'trash-alt']"
              class="color-icon-trash fa-lg"
            />
          </button>
        </div>

        <!-- <div v-if="data.item.canDelete" class="text-center">
            
          </div>
          <div v-else class="text-center">
            <font-awesome-icon
              :icon="['fas', 'trash-alt']"
              class="color-icon-trash"
              disabled
            />
          </div> -->
      </template>
    </b-table>

    <div class="assessment-form-start-bg">
      <div class="assessment-form-start">
        <div
          class="
            headline-list headline-hr
            align-items-center
            d-flex
            mb-4
            weight-500
            fontsize-h5
          "
        >
          <span class="font-headblue">กรอกข้อมูลเพื่อเริ่มทำ</span
          ><span class="font-deeporange weight-700"
            >แบบประเมินคุณภาพระบบประปา</span
          >
        </div>
        <div class="blue-box">
          <div class="header">
            กรุณาระบุที่ตั้งของประปาหมู่บ้าน
            เพื่อเริ่มทำแบบประเมินคุณภาพระบบประปาหมู่บ้าน
          </div>
          <div class="content">
            <div class="row mb-2">
              <div class="col-6 col-md-4 align-items-center">
                <div class="d-flex form-group">
                  <label class="col-form-label">จังหวัด</label>
                  <div class="w-100">
                    <select2
                      class="select-custom"
                      :options="provinceList"
                      v-model="proviceSelect"
                      :settings="{
                        language: {
                          noResults: function () {
                            return 'ไม่พบข้อมูล';
                          },
                        },
                      }"
                      @change="changeProvince($event)"
                    >
                    </select2>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-4 align-items-center">
                <div class="d-flex form-group">
                  <label class="col-form-label">อำเภอ</label>
                  <div class="w-100">
                    <select2
                      class="select-custom"
                      :options="amphurList"
                      v-model="amphurSelect"
                      :settings="{
                        language: {
                          noResults: function () {
                            return 'ไม่พบข้อมูล';
                          },
                        },
                      }"
                      @change="changeAmphur($event)"
                    >
                    </select2>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-4 align-items-center">
                <div class="d-flex form-group">
                  <label class="col-form-label">ตำบล</label>
                  <div class="w-100">
                    <select2
                      class="select-custom"
                      :options="tambolList"
                      v-model="tambolSelect"
                      :settings="{
                        language: {
                          noResults: function () {
                            return 'ไม่พบข้อมูล';
                          },
                        },
                      }"
                      @change="changeTambol($event)"
                    >
                    </select2>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-4 align-items-center">
                <div class="d-flex form-group">
                  <label class="col-form-label">ชื่อหมู่บ้าน</label>
                  <div class="w-100">
                    <select2
                      class="select-custom"
                      :options="villageList"
                      v-model="villageSelect"
                      :settings="{
                        language: {
                          noResults: function () {
                            return 'ไม่พบข้อมูล';
                          },
                        },
                      }"
                      @change="changeVillage($event)"
                    >
                    </select2>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-4 align-items-center">
                <div class="d-flex form-group">
                  <label class="col-form-label">ชื่อระบบประปา</label>
                  <div class="w-100">
                    <input
                      type="text"
                      v-model="systemName"
                      placeholder="เช่น อยู่ในโรงเรียน, วัด, อยู่ข้างศาลาวัด"
                      class="form-control w-100"
                    />
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-4 align-items-center">
                <div class="d-flex form-group">
                  <label class="col-form-label">หมู่</label>
                  <div class="w-100">
                    <input
                      type="text"
                      class="form-control w-100"
                      v-model="villageNo"
                    />
                  </div>
                </div>
              </div>
              <div class="col-6 align-items-center">
                <div class="form-group d-flex">
                  <label class="col-form-label">ประเภทแหล่งน้ำ</label>
                  <div class="align-items-center d-flex">
                    <b-form-radio-group
                      class="my-radio"
                      v-model="waterType"
                      inline
                    >
                      <b-form-radio value="1" style="width: 140px"
                        >แหล่งน้ำผิวดิน</b-form-radio
                      >
                      <b-form-radio value="2" style="width: 140px"
                        >แหล่งน้ำบาดาล</b-form-radio
                      >
                    </b-form-radio-group>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-12">
                <span class="weight-700">เลือกระบุ</span>
                <span class="mx-1">ค่าพิกัดที่ตั้ง เป็น</span>
                <span class="weight-700 mx-1">UTM</span><span>หรือ</span>
                <span class="weight-700 mx-1">Lat/Long</span>
                <span>ข้อใดข้อหนึ่ง :</span>
              </div>
            </div>
            <div class="row mb-3 align-items-center border-coordinates">
              <div class="col-lg-2 col-3 font-greenmint weight-700">
                <span class="icon-coordinates">
                  <iconify
                    class="font-deepyellow mr-2"
                    icon="mdi:triangle"
                    :rotate="1"
                    :inline="true"
                  ></iconify>
                </span>
                ค่า UTM
              </div>
              <div class="col">
                <div class="d-flex form-group">
                  <label class="col-form-label">โซน</label>
                  <div class="w-100">
                    <select2
                      class="select-custom"
                      :options="zoneList"
                      v-model="utm.zone"
                      :settings="{
                        language: {
                          noResults: function () {
                            return 'ไม่พบข้อมูล';
                          },
                        },
                      }"
                    >
                    </select2>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="d-flex form-group">
                  <label class="col-form-label">พิกัดหมู่บ้าน X</label>
                  <div class="w-100">
                    <input
                      type="number"
                      class="form-control"
                      v-model="utm.xVal"
                    />
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="d-flex form-group">
                  <label class="col-form-label">พิกัดหมู่บ้าน Y</label>
                  <div class="w-100">
                    <input
                      type="number"
                      class="form-control"
                      v-model="utm.yVal"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-2 align-items-center border-coordinates">
              <div class="col-lg-2 col-3 font-greenmint weight-700">
                <span class="icon-coordinates">
                  <iconify
                    class="font-deepyellow mr-2"
                    icon="mdi:triangle"
                    :rotate="1"
                    :inline="true"
                  ></iconify>
                </span>
                ค่าละติจูด / ลองจิจูด
              </div>
              <div class="col">
                <div class="d-flex form-group">
                  <label class="col-form-label">กำหนดค่าละติจูด</label>
                  <div class="w-100">
                    <input
                      type="number"
                      class="form-control"
                      v-model="latlng.lat"
                    />
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="d-flex form-group">
                  <label class="col-form-label">กำหนดค่าลองจิจูด</label>
                  <div class="w-100">
                    <input
                      type="number"
                      class="form-control"
                      v-model="latlng.lng"
                    />
                  </div>
                </div>
              </div>
              <div class="col"></div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center mt-5">
          <div class="col-4">
            <button
              type="button"
              class="btn btn-submit w-100"
              @click="gotoStartForm"
            >
              เริ่มต้นทำแบบประเมิน
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterService, AssessmentService } from "@/services/main.service.js";
import { UserCardIdService } from "@/services/storage.service";

export default {
  name: "assessment-list",
  data() {
    return {
      rootBreadcrumb: {
        icon: null,
        text: "หน้าหลักประเมินคุณภาพระบบประปาหมู่บ้าน",
        to: "AssessmentList",
      },
      items: [],
      fields: [
        { key: "index", label: "ลำดับ" },
        { key: "villageName", label: "หมู่บ้าน/ชุมชน", align: "left" },
        { key: "villageNameNumber", label: "หมู่", align: "left" },
        { key: "tambolName", label: "ตำบล", align: "left" },
        { key: "amphorName", label: "อำเภอ", align: "left" },
        { key: "provinceName", label: "จังหวัด", align: "left" },
        { key: "systemPlumbingName", label: "ชื่อระบบประปา", align: "left" },
        { key: "pwaType", label: "ประเภทแหล่งน้ำ", align: "left" },
        { key: "result", label: "ผลการประเมิน", align: "left" },
        { key: "viewResult", label: "ดูสรุปผล", align: "left" },
        { key: "modifyDate", label: "วันที่อัพเดทข้อมูล", align: "left" },
        { key: "canEdit", label: "แก้ไข" },
        { key: "canDelete", label: "ลบ" },
      ],
      isBusy: false,
      total: 0,
      provinceList: [],
      proviceSelect: null,
      amphurList: [],
      amphurSelect: null,
      tambolList: [],
      tambolSelect: null,
      villageList: [],
      villageSelect: null,
      systemName: null,
      villageNo: null,
      type: null,
      utm: {
        zone: null,
        xVal: null,
        yVal: null,
      },
      latlng: {
        lat: null,
        lng: null,
      },
      zoneList: [],
      waterType: null,
      personId: null,
    };
  },
  async created() {
    this.personId = UserCardIdService.getUserCardId();

    this.getQueryString();
    this.getListQualitySurvey();
    this.getZone();
    this.getProvince();
  },
  methods: {
    viewAll() {
      this.$router.push({ name: "AssessmentListAll" });
    },
    validate() {
      if (this.proviceSelect === null) return false;
      if (this.amphurSelect === null) return false;
      if (this.tambolSelect === null) return false;
      // if (this.villageSelect === null) return false;
      if (this.systemName === null || this.systemName === "") return false;
      // if (this.villageNo === null || this.villageNo === "") return false;
      if (this.waterType === null) return false;
      if (
        this.utm.zone === null ||
        this.utm.xVal === null ||
        this.utm.yVal === null
      ) {
        if (this.latlng.lat === null || this.latlng.lng === null) return false;
      }
      return true;
    },
    gotoStartForm() {
      if (this.validate()) {
        const param = {
          fromquery: 1,
          pro: this.proviceSelect,
          amp: this.amphurSelect,
          tam: this.tambolSelect,
          moo: this.villageNo,
          village: this.villageSelect,
          systemName: this.systemName,
          zone: this.utm.zone,
          utme: this.utm.xVal,
          utmn: this.utm.yVal,
          latitude: this.latlng.lat,
          longitude: this.latlng.lng,
        };
        if (this.waterType == 1) {
          this.$router.push({
            name: "SurfaceForm",
            params: { mode: "add" },
            query: {
              ...param,
            },
          });
        } else if (this.waterType == 2) {
          this.$router.push({
            name: "GroundForm",
            params: { mode: "add" },
            query: {
              ...param,
            },
          });
        }
      } else {
        this.alertAssessmentFormValidate(
          "กรุณาระบุที่ตั้งของประปาหมู่บ้านให้ครบค่ะ"
        );
      }
    },
    async getListQualitySurvey() {
      this.isBusy = true;

      const param = {
        personId: this.personId,
        NumPage: 1,
        NumRow: 5,
      };
      await AssessmentService.getListQualitySurvey(param)
        .then((res) => {
          this.items = res.data.map((m, index) => {
            return {
              ...m,
              index: index + 1,
              canEdit: true,
              canDelete: true,
            };
          });
          this.total = res.total;
        })
        .catch((err) => {
          this.items = [];
          this.total = 0;
        })
        .finally((f) => {
          this.isBusy = false;
        });
    },
    async getZone() {
      this.zoneList = await FilterService.getListZone().then((res) => {
        return res;
      });
    },
    async getProvince() {
      await FilterService.getListProvince()
        .then((res) => {
          this.provinceList = res.data;
        })
        .catch((err) => {
          this.provinceList = [];
        });
    },
    async getAmphur(provinceId = null, id = null) {
      await FilterService.getListAmphur(provinceId)
        .then((res) => {
          this.amphurList = res.data;
        })
        .catch((err) => {
          this.amphurList = [];
        });
    },
    async getTambol(amphurId = null, id = null) {
      await FilterService.getListTambol(amphurId)
        .then((res) => {
          this.tambolList = res.data;
        })
        .catch((err) => {
          this.tambolList = [];
        });
    },
    async getVillage(tambolId = null, id = null) {
      await FilterService.getListVillage(tambolId)
        .then((res) => {
          this.villageList = res.data;
        })
        .catch((err) => {
          this.villageList = [];
        });
    },
    changeProvince(event) {
      const id = event;

      //clear
      this.amphurSelect = null;
      this.tambolSelect = null;
      this.tambolList = [];
      this.villageSelect = null;
      this.villageList = [];
      this.villageNo = null;

      this.getAmphur(id);
    },
    changeAmphur(event) {
      const id = event;

      //clear
      this.tambolSelect = null;
      this.villageSelect = null;
      this.villageList = [];
      this.villageNo = null;

      this.getTambol(id);
    },
    changeTambol(event) {
      const id = event;
      //clear
      this.villageSelect = null;
      this.villageNo = null;

      this.getVillage(id);
    },
    changeVillage(event) {
      const village = this.villageList.find((f) => f.id == event);
      this.villageNo = village?.villageNo || null;
    },
    onViewResult(detail) {
      const param = {
        surveyID: detail.qualitySurveyID,
        type: detail.pwaType,
      };
      this.$router.push({
        name: "AssessmentSummary",
        query: {
          ...param,
        },
      });
    },
    editRow(detail) {
      const param = {
        surveyID: detail.qualitySurveyID,
        type: detail.pwaType,
      };
      if (detail.pwaTypeId === 1) {
        this.$router.push({
          name: "SurfaceForm",
          params: { mode: "edit" },
          query: {
            ...param,
          },
        });
      } else if (detail.pwaTypeId === 2) {
        this.$router.push({
          name: "GroundForm",
          params: { mode: "edit" },
          query: {
            ...param,
          },
        });
      }
    },
    deleteRow(detail) {
      this.alertDeleteForm().then(async (result) => {
        if (result) {
          //call service delete
          await AssessmentService.postAssessmentFormDelete(
            detail.qualitySurveyID
          )
            .then((res) => {
              if (res.actionId !== 0) {
                this.alertSuccess("ลบรายการสำเร็จ");
                //refresh list
                this.getListQualitySurvey();
              }
            })
            .catch((err) => {
              this.alertFail("ลบรายการไม่สำเร็จ");
            });
        }
      });
    },
    getIcon(item) {
      if (item === "A") return "bi:emoji-heart-eyes-fill";
      else if (item === "B") return "bi:emoji-smile-fill";
      else if (item === "C") return "bi:emoji-wink-fill";
      else if (item === "D") return "bi:emoji-neutral-fill";
      else if (item === "F") return "bi:emoji-frown-fill";
    },
    getColorIcon(item) {
      if (item === "A") return "font-graph-green";
      else if (item === "B") return "font-graph-softgreen";
      else if (item === "C") return "font-graph-yellow";
      else if (item === "D") return "font-graph-orange";
      else if (item === "F") return "font-graph-rad";
    },
    async getQueryString() {
      const query = { ...this.$route.query };

      if (query.pwaTypeId) {
        this.waterType = query.pwaTypeId;
      }
      if (query.provinceId) {
        this.proviceSelect = query.provinceId;

        await this.getAmphur(query.provinceId);

        if (query.amphorId) {
          this.amphurSelect = query.amphorId;

          await this.getTambol(query.amphorId);

          if (query.tambolId) {
            this.tambolSelect = query.tambolId;

            await this.getVillage(query.tambolId);
            this.villageNo = query.villageNo;
            if (query.villageId) {
              this.villageSelect = query.villageId;
            }
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.assessment-form-start-bg {
  background-color: white;
  margin-left: -#{$content-wrapper-padding};
  margin-right: -#{$content-wrapper-padding};
  margin-bottom: -#{$content-wrapper-padding};
  .assessment-form-start {
    padding: $content-wrapper-padding;
    // padding-top: $content-wrapper-padding;
    // padding-left: $content-wrapper-padding;
    // padding-right: $content-wrapper-padding;
  }
}

.assessment-form-start {
  .colonn {
    margin: 0 0.75rem;
  }
  .form-group {
    > label {
      margin-bottom: 0;
      min-width: 170px;
      max-width: 170px;
      text-align: right;
      &:after {
        content: ":";
        margin: 0 0.75rem;
      }
    }
  }

  .border-coordinates {
    padding: 1rem;
    border-radius: 6px;
    border: 0.5px solid #1ca2df;
    box-sizing: border-box;
    background: white;
    .form-group {
      margin-bottom: 0;
      > label {
        min-width: 150px;
        max-width: 150px;
        text-align: right;
        &:after {
          content: ":";
          margin: 0 0.75rem;
        }
      }
    }
  }
  .icon-coordinates {
    font-size: 10px;
  }
}

.blue-box {
  .content {
    background-color: $whiteblue;
    box-shadow: unset;
  }
  box-shadow: unset;
}
</style>
